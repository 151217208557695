import React from "react";
import { Link } from "gatsby";
// import "normalize.css";
import SendIcon from "@material-ui/icons/Send";
import { createMuiTheme, useTheme } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import {
  Container,
  Grid,
  TextField,
  Button,
  Breadcrumbs,
  Typography,
  makeStyles,
  ThemeProvider,
  Hidden,
  Snackbar,
  useMediaQuery,
} from "@material-ui/core";
import TitlePage from "../components/Typography/TitlePage";
import Text from "../components/Typography/Text";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import DefaultLayout from "../layout/DefaultLayout";
import axios from "axios";

import { useStaticQuery, graphql } from "gatsby";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const themeProvider = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&$focused $notchedOutline": {
          borderColor: "#002E5D",
        },
        // borderColor: "#002E5D",
        borderRadius: 12,
        fontFamily: "Poppins",
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "#002E5D",
        },
        fontFamily: "Poppins",
        // borderRadius: 12,
      },
    },
    /*MuiButton: {
      containedPrimary: {
        borderRadius: 12,
        height: "56px",
        color: "#fff", // color text
        backgroundColor: "#002E5D",
        fontFamily: "Poppins",
        textTransform: "capitalize",
        "&:hover": {
          backgroundColor: "rgb(0, 46, 93,0.6)",
        },
      },
    },*/
  },
});

const useStyles = makeStyles((theme) => ({
  button: {
    // margin: theme.spacing(1),
    height: "56px",
    color: "#fff",
    backgroundColor: "#002E5D",
    fontFamily: "Poppins",
    textTransform: "capitalize",
    borderRadius: 12,
    "&:hover": {
      backgroundColor: "#002E5D",
      "@media (hover: none)": {
        backgroundColor: "#002E5D",
      },
    },
  },
}));

const Contacts = ({ location }) => {
  const pathname = location.pathname;
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md")); //md=960px

  const [state, setState] = React.useState({
    name: "",
    nameError: false,
    email: "",
    emailError: false,
    subject: "Contact",
    message: "",
    messageError: false,
    openSnackbar: false,
    snackbarMessage: "",
    severity: "error", //success
  });

  const onSubmit = async () => {
    return new Promise(async (resolve, reject) => {
      let completed = false;

      setTimeout(() => {
        if (!completed) {
          completed = true;
          return reject("");
        }
      }, 15000);

      try {
        let res = await axios.post(
          `https://legacy.mentorme.md/rest-auth/subscribe`,
          {
            name: state.name,
            email: state.email,
            subject: state.subject,
            body: state.message,
          },
          { headers: { "Content-Type": "application/json" } }
        );
        if (!completed) {
          completed = true;
          return resolve(res.data);
        }
      } catch (err) {
        if (!completed) {
          completed = true;
          return reject(err);
        }
      }
    });
  };

  const onSendClick = async () => {
    try {
      let { message, email, name } = state;
      if ([!message.length, !email.length, !name.length].includes(true)) {
        // there are empty fields
        setState({
          ...state,
          openSnackbar: true,
          snackbarMessage: "There are empty fields",
          severity: "error",
        });
      } else {
        // ready to post request
        await onSubmit();
        setState({
          ...state,
          openSnackbar: true,
          snackbarMessage: "The message was successfully sent",
          severity: "success",
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onCloseSnackbar = () => {
    setState({ ...state, openSnackbar: false });
  };

  const data = useStaticQuery(graphql`
    {
      allContentfulContacts {
        nodes {
          text
        }
      }
    }
  `).allContentfulContacts.nodes[0].text;

  return (
    <DefaultLayout location={pathname}>
      <Container>
        <ThemeProvider theme={themeProvider}>
          <Snackbar
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={state.openSnackbar}
            onClose={onCloseSnackbar}
          >
            <Alert severity={state.severity}>
              {state.snackbarMessage || ""}
            </Alert>
          </Snackbar>
          <Grid
            container
            alignItems="center"
            spacing={3}
            justify={md ? "center" : "flex-start"}
          >
            <Grid item xs={12} style={{ alignContent: "start" }}>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                <Link to="/" className="heading_route">
                  {"Acasă"}
                </Link>
                <Typography
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontStyle: "normal",
                    color: "#000",
                  }}
                >
                  {"Contact"}
                </Typography>
              </Breadcrumbs>
            </Grid>

            <Grid item xs={md ? 7 : 12}>
              <TitlePage variant={md ? "h3" : "h4"} centered={md}>
                {"Contactează-ne!"}
              </TitlePage>
            </Grid>
            <Grid item xs={md ? 7 : 12}>
              <Text variant={md ? "h6" : "body1"} centered={md}>
                {data}
              </Text>
            </Grid>
            <Hidden mdUp>
              {/**
               * MOBILE
               */}
              <Grid item xs={12}>
                <TextField
                  // margin="normal"
                  variant="outlined"
                  fullWidth
                  required
                  error={state.nameError || false}
                  key="name"
                  id="name"
                  name="name"
                  label="Nume"
                  value={state.name || ""}
                  onChange={(e) =>
                    setState({
                      ...state,
                      name: e.target.value,
                      nameError: !String(e.target.value).length,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  error={state.emailError || false}
                  // margin="normal"
                  variant="outlined"
                  fullWidth
                  type="email"
                  id="emai"
                  key="email"
                  name="email"
                  value={state.email || ""}
                  label="Email"
                  onChange={(e) =>
                    setState({
                      ...state,
                      email: e.target.value,
                      emailError: !String(e.target.value).length,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  error={state.messageError || false}
                  variant="outlined"
                  fullWidth
                  id="message"
                  name="message"
                  key="message"
                  label="Mesaj"
                  value={state.message || ""}
                  onChange={(e) =>
                    setState({
                      ...state,
                      message: e.target.value,
                      messageError: !String(e.target.value).length,
                    })
                  }
                  rows={4}
                  multiline
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={onSendClick}
                  className={classes.button}
                  endIcon={<SendIcon fontSize="large" />}
                >
                  {"Trimite"}
                </Button>
              </Grid>
            </Hidden>
            <Hidden smDown>
              <Grid item xs={7}>
                <TextField
                  required
                  error={state.nameError || false}
                  variant="outlined"
                  fullWidth
                  key="name"
                  id="name"
                  name="name"
                  label="Nume"
                  value={state.name || ""}
                  onChange={(e) =>
                    setState({
                      ...state,
                      name: e.target.value,
                      nameError: !String(e.target.value).length,
                    })
                  }
                />
              </Grid>
              <Grid item xs={7}>
                <TextField
                  required
                  error={state.emailError || false}
                  variant="outlined"
                  fullWidth
                  type="email"
                  id="emai"
                  value={state.email || ""}
                  key="email"
                  name="email"
                  label="Email"
                  onChange={(e) =>
                    setState({
                      ...state,
                      email: e.target.value,
                      emailError: !String(e.target.value).length,
                    })
                  }
                />
              </Grid>
              <Grid item xs={7}>
                <TextField
                  required
                  error={state.messageError || false}
                  variant="outlined"
                  fullWidth
                  id="message"
                  name="message"
                  key="message"
                  label="Mesaj"
                  value={state.message || ""}
                  onChange={(e) =>
                    setState({
                      ...state,
                      message: e.target.value,
                      messageError: !String(e.target.value).length,
                    })
                  }
                  rows={4}
                  multiline
                />
              </Grid>
              <Grid item xs={7}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={onSendClick}
                  className={classes.button}
                  endIcon={<SendIcon fontSize="large" />}
                >
                  {"Trimite"}
                </Button>
              </Grid>
            </Hidden>
          </Grid>
        </ThemeProvider>
      </Container>
    </DefaultLayout>
  );
};

export default Contacts;
